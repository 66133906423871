var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog",attrs:{"title":_vm.lang == 'ZH' ? '回顾' : 'Review',"visible":_vm.visible,"append-to-body":"","width":"90%","show-close":true,"close-on-click-modal":false,"close-on-press-escape":false},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"form-container"},[_c('el-form',{ref:"reviewForm",attrs:{"model":_vm.reviewForm,"rules":_vm.reviewRules}},[_vm._l((_vm.filteredQuestionList),function(question,index){return _c('el-form-item',{key:index},[_c('div',[_vm._v(" "+_vm._s(_vm.lang == "ZH" ? "问题" : "Question")+" "+_vm._s(question.index)+": "),(question.is_hidden == true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(Hidden)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.lang == "ZH" ? question.question_zh : question.question_en)+" ")]),(question.type == 'multiple_object')?_c('div',[_c('el-table',{attrs:{"data":question.answers,"border":""}},[_vm._l((question.choices['Yes']),function(column,columnIndex){return _c('el-table-column',{key:columnIndex,staticStyle:{"word-break":"normal"},attrs:{"label":_vm.questionList[column - 1].index +
                  ': ' +
                  (_vm.questionList[column - 1].is_hidden ? '(Hidden)' : '') +
                  _vm.multiQnsInReview(column),"width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.questionList[column - 1].type == 'single_choice')?[_c('el-select',{attrs:{"placeholder":_vm.lang == 'ZH' ? '请选择' : 'Please select'},on:{"change":_vm.change},model:{value:(question.answers[scope.$index][columnIndex]),callback:function ($$v) {_vm.$set(question.answers[scope.$index], columnIndex, $$v)},expression:"question.answers[scope.$index][columnIndex]"}},_vm._l((_vm.getChoices(
                        _vm.questionList[column - 1]
                      )),function(choice,index){return _c('el-option',{key:index,attrs:{"label":choice,"value":choice}})}),1)]:_vm._e(),(_vm.getChoices(_vm.questionList[column - 1]).length == 0)?[_c('el-input',{model:{value:(question.answers[scope.$index][columnIndex]),callback:function ($$v) {_vm.$set(question.answers[scope.$index], columnIndex, $$v)},expression:"question.answers[scope.$index][columnIndex]"}})]:_vm._e()]}}],null,true)})}),_c('el-table-column',{staticStyle:{"overflow-wrap":"break-word"},attrs:{"label":_vm.lang == 'ZH' ? '操作' : 'Operation',"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.handleDelete(question, scope.$index)}}},[_vm._v(_vm._s(_vm.lang == "ZH" ? "删除" : "Delete"))])]}}],null,true)})],2),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleAdd(question)}}},[_vm._v(_vm._s(_vm.lang == "ZH" ? "新增一行" : "Add a row"))])],1):_vm._e(),(
            _vm.getChoices(question).length > 0 &&
              question.type == 'multiple_choice' &&
              question.type != 'multiple_object'
          )?_c('el-select',{attrs:{"placeholder":_vm.lang == 'ZH' ? '请选择' : 'Please select',"multiple":""},on:{"change":function($event){return _vm.change(question)}},model:{value:(question.answers),callback:function ($$v) {_vm.$set(question, "answers", $$v)},expression:"question.answers"}},_vm._l((_vm.getChoices(question)),function(choice,index){return _c('el-option',{key:index,attrs:{"label":choice,"value":choice}})}),1):_vm._e(),(
            _vm.getChoices(question).length > 0 &&
              question.type != 'multiple_choice' &&
              question.type != 'multiple_object'
          )?_c('el-select',{attrs:{"placeholder":_vm.lang == 'ZH' ? '请选择' : 'Please select'},on:{"change":function($event){return _vm.change(question)}},model:{value:(question.answers[0]),callback:function ($$v) {_vm.$set(question.answers, 0, $$v)},expression:"question.answers[0]"}},_vm._l((_vm.getChoices(question)),function(choice,index){return _c('el-option',{key:index,attrs:{"label":choice,"value":choice}})}),1):_vm._e(),(_vm.getChoices(question).length == 0)?_c('el-input',{attrs:{"disabled":question.type == 'address'},model:{value:(question.answers[0]),callback:function ($$v) {_vm.$set(question.answers, 0, $$v)},expression:"question.answers[0]"}}):_vm._e()],1)}),_c('el-form-item',{attrs:{"label":_vm.lang == 'ZH' ? '估值报告货币单位' : 'Valuation Report Currency',"prop":"curr"}},[_c('el-select',{attrs:{"placeholder":_vm.lang == 'ZH' ? '请选择货币单位' : 'Please select currency'},model:{value:(_vm.reviewForm.curr),callback:function ($$v) {_vm.$set(_vm.reviewForm, "curr", $$v)},expression:"reviewForm.curr"}},_vm._l((_vm.curr_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitBtnLoading,"disabled":_vm.submitBtnLoading},on:{"click":_vm.submitToServer}},[_vm._v(_vm._s(_vm.lang == "ZH" ? "提交" : "Submit"))])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }